<template>
  <div id="DirectTransactionChange"></div>
</template>
<script>
//this page is simply so the transactions menu will work even within the transactions page, router does not reload page without a change.
export default {
  props: {
    vin: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    make: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    },
    year: { type: [Number, String], default: null, required: false },
    placard: {
      type: String,
      default: () => {
        return "";
      },
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    if (this.vin !== "" && this.vin !== undefined) {
      this.$router.replace({
        name: "Transaction",
        params: {
          vin: this.vin,
          make: this.make,
          year: this.year
        }
      });
    } else if (this.placard !== "") {
      this.$router.replace({
        name: "PlacardTransaction",
        params: {
          placard: this.placard
        }
      });
    } else {
      this.$router.replace({ name: "NewTransaction" });
    }
  }
};
</script>
<style scoped></style>
